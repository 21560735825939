import PageContainer from "components/ui/PageContainer"
import useCurrentUser from "loaders/useCurrentUser"
import useSharedStyles from "components/useSharedStyles"
import {Box, Divider, Typography} from "@material-ui/core"
import React, {useLayoutEffect} from "react"
import {useQuickQuote} from "../orders/submit-order/useQuickQuote";
import {useOrganizationSelection} from "context/OrganizationSelectionProvider"
import {SalesPromptLogin} from "../SalesPromptLogin"
import useQuery from "../../../hooks/useQuery";
import {useHistory, useParams} from "react-router-dom";
import {routes} from "../../../routes";
import { IRouteParams } from "AppRoutes"
import endpoints from "endpoints"
import { IQuote } from "../IQuote"
import useSWR from "swr"
import { IOrganizationProfile } from "types/IOrganizationProfile"

export const CreateQuoteStart: React.FC = () => {
  return (
    <PageContainer>
      <CreateQuoteFormWrapper />
    </PageContainer>
  )
}

export const CreateQuoteFormWrapper: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const params = useQuery()
  const history = useHistory()
  const { setIsNewOrganization, setOrganizationProfile, setSiteType, setDisplayName, setHasCurriculum } = useOrganizationSelection()
  const { secret_code } = useParams<IRouteParams>();
  const { data: editQuote, error } = useSWR<IQuote>(endpoints.quote(secret_code));
  const editQuoteOrganizationProfileIncludes = ['administrator', 'teachers', 'subscriptions', 'licenses', 'clever', 'payment', 'purchase_process', 'current_subscription', 'quote_request_v2'];
  const organizationProfileEndpoint = editQuote?.organization_profile_id? endpoints?.godmode?.organizationProfile?.byId(editQuote.organization_profile_id.toString(), editQuoteOrganizationProfileIncludes) : null;
  const { data: quoteOrganizationProfileData, mutate } = useSWR<IOrganizationProfile>(organizationProfileEndpoint);

  useLayoutEffect(() => {
    setHasCurriculum(params.get('curriculum') === 'true')

    if (!currentUser) {
      return
    }
    if (editQuote) {
      const quoteOrganizationProfile = quoteOrganizationProfileData? quoteOrganizationProfileData : null;
  
      if (quoteOrganizationProfile?.school_id) {
        setSiteType('school')
      }
      if (quoteOrganizationProfile?.district_id) {
        setSiteType('district')
      }
      if (!quoteOrganizationProfile?.district_id && !quoteOrganizationProfile?.school_id) {
        setSiteType('other')
      }
      setDisplayName(editQuote.organization_name)
      setIsNewOrganization(!quoteOrganizationProfile)
      setOrganizationProfile(quoteOrganizationProfile)
      history.replace(routes.sales.quotes.edit(secret_code))
      return
    }
    if (currentUser?.organization_profile) {
      if (currentUser?.organization_profile?.school_id) {
        setSiteType('school')
      }
      if (currentUser?.organization_profile?.district_id) {
        setSiteType('district')
      }
      if (!currentUser?.organization_profile?.district_id && !currentUser?.organization_profile?.school_id) {
        setSiteType('other')
      }
      setDisplayName(currentUser?.organization_profile?.display_name)
      setIsNewOrganization(false)
      setOrganizationProfile(currentUser.organization_profile)
      history.replace(routes.sales.quotes.create.form)
      return
    } else {
      history.replace(routes.sales.quotes.create.selectLocation)
    }
  }, [currentUser, setDisplayName, setIsNewOrganization, setOrganizationProfile, setSiteType])

  return (
    <PageContainer variant="centered">
      <SalesPromptLogin />
    </PageContainer>
  )
}

export const QuickQuoteBlock: React.VFC = () => {
  const sharedClasses = useSharedStyles();
  const { quote } = useQuickQuote();

  return <>
    {!!quote && <Box display="flex" flexDirection="column" p={2} bgcolor="rgb(235,235,235)" borderRadius={6} height="100%">
      <Box display="flex" justifyContent="space-between" className={sharedClasses.hspacing2}>
        <Typography variant="subtitle1">List price per site:</Typography>
        <Typography style={{ minWidth: 75, textAlign: 'right' }}>${quote?.list_price_per_site.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</Typography>
      </Box>
      <Box py={1}>
        <Divider />
      </Box>
      {!!quote?.discount && <Box display="flex" justifyContent="space-between" className={sharedClasses.hspacing2}>
        <Typography variant="subtitle1">Subtotal:</Typography>
        <Typography style={{ minWidth: 75, textAlign: 'right' }}>${quote?.list_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</Typography>
      </Box>}
      {!!quote?.discount && <Box display="flex" justifyContent="space-between" className={sharedClasses.hspacing2}>
        <Typography variant="subtitle1">Volume discount:</Typography>
        <Typography style={{ minWidth: 75, textAlign: 'right' }}>${quote?.discount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</Typography>
      </Box>}
      <Box display="flex" justifyContent="space-between" className={sharedClasses.hspacing2}>
        <Typography variant="subtitle1">Total:</Typography>
        <Typography style={{ minWidth: 75, textAlign: 'right' }}>${quote?.net_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</Typography>
      </Box>
    </Box>}
  </>;
}
