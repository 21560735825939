import React, {useCallback, useLayoutEffect, useMemo, useState} from 'react';
import {
  Box,
  Checkbox, CircularProgress, Dialog, DialogActions,
  DialogContent,
  DialogTitle, LinearProgress,
  Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/ui/buttons/Button";
import { DialogStateProps } from "../../../hooks/useDialogState";
import useSWR from "swr";
import endpoints from "../../../endpoints";
import { justFetch } from "../../../mutations/mutate";
import Amplitude from "../../../utils/Amplitude";
import { ICleverClass } from "../../../types/ICleverClass";
import { IKlass } from "../../../types/IKlass";
import { cleverGradeToKodableGrade } from "../utils/cleverToKodableGrade";
import { CleverLoginButton } from "../../login/LoginInput";
import {useClever} from "../../../context/CleverProvider";
import useTeacherInit from "../../../loaders/useTeacherInit";
import Tooltip from "../../../components/ui/Tooltip";

type SubmitStatusType = 'error' | 'submitting' | 'success';
type SubmitStatus = Record<string, SubmitStatusType>;

interface ManageKlassesDialogProps extends DialogStateProps {}

const ManageKlassesDialog: React.FC<ManageKlassesDialogProps> = ({ open, onClose }) => {
  const [selectedClasses, setSelectedClasses] = useState<Set<string>>(new Set());
  const {mutate: mutateInit} = useTeacherInit()
  const {useKlasses, useStudents} = useClever()
  const {mutate: mutateKlasses} = useKlasses();
  const {mutate: mutateStudents} = useStudents();
  const {teacherData, isValidating} = useTeacherInit()
  const { data: cleverClasses, error: cleverClassesError } = useSWR<ICleverClass[]>(endpoints.cleverKlasses);
  const [allKlasses, setAllKlasses] = useState<IKlass[]>();
  const [cleverIds, setCleverIds] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>({});

  const handleToggleClass = useCallback((classId: string, selected: boolean) => {
    setSelectedClasses(prev => {
      const newSet = new Set(prev);
      if (selected) {
        newSet.add(classId);
      } else {
        newSet.delete(classId);
      }
      return newSet;
    });
  }, []);

  const getStaleKlasses = () => {
    return allKlasses?.filter(({ clever_id }) => clever_id && !cleverIds.includes(clever_id))
      .map(({clever_id}) => clever_id) as string[]
  }

  useLayoutEffect(() => {
    if (!teacherData?.klasses || !cleverClasses) {
      return;
    }
    const alreadyImported = teacherData?.klasses.filter(({ clever_id }) => !!clever_id) || [];
    setAllKlasses(alreadyImported)
    setCleverIds(cleverClasses.map(({data}) => data.id))
    setSelectedClasses(new Set(cleverClasses.filter(cleverClass =>
      alreadyImported.some(klass => klass.clever_id === cleverClass.data.id)
    ).map(({ data: { id } }) => id)));
  }, [cleverClasses, teacherData?.klasses]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const initialStatus: SubmitStatus = {};
    cleverClasses?.forEach(({ data: { id } }) => {
      initialStatus[id] = 'submitting';
    });
    getStaleKlasses().forEach(id => {
      initialStatus[id] = 'submitting';
    });
    setSubmitStatus(initialStatus);

    try {
      const stalePromises = getStaleKlasses().map(clever_id => handleDeleteClass(clever_id));
      const importPromises = Array.from(selectedClasses).map(classId => handleImportClass(classId));
      const deletePromises = cleverClasses
        ?.filter(({ data: { id } }) => !selectedClasses.has(id))
        .map(({ data: { id } }) => handleDeleteClass(id)) || [];

      const results = await Promise.allSettled([...importPromises, ...deletePromises, ...stalePromises]);

      const newSubmitStatus: SubmitStatus = { ...initialStatus };
      results.forEach((result, index) => {
        let classId: string | undefined;
        if (index < selectedClasses.size) {
          classId = Array.from(selectedClasses)[index];
        } else if (index < selectedClasses.size + (cleverClasses?.length || 0) - selectedClasses.size) {
          const nonSelectedClasses = cleverClasses?.filter(({ data: { id } }) => !selectedClasses.has(id));
          if (nonSelectedClasses) {
            classId = nonSelectedClasses[index - selectedClasses.size]?.data.id;
          }
        } else {
          classId = getStaleKlasses()[index - (selectedClasses.size + (cleverClasses?.length || 0) - selectedClasses.size)];
        }
        if (classId) {
          newSubmitStatus[classId] = result.status === 'fulfilled' ? 'success' : 'error';
        }
      });

      setSubmitStatus(newSubmitStatus);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
      mutateKlasses();
      mutateStudents();
      mutateInit();
      onClose();
    }
  };

  const handleImportClass = async (cleverClassId: string): Promise<void> => {
    try {
      const cleverClass = cleverClasses?.find(({ data: { id } }) => id === cleverClassId);
      if (!cleverClass) {
        throw new Error('Clever class not found');
      }

      // Check if the class has already been imported
      const existingClass = allKlasses?.find(klass => klass.clever_id === cleverClassId);
      if (existingClass) {
        setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'success' }));
        return;
      }

      const res = await justFetch(endpoints.cleverKlasses, 'POST', {
        clever_klasses: JSON.stringify([{
          clever_id: cleverClassId,
          grade: cleverGradeToKodableGrade(cleverClass.data.grade)
        }])
      });

      if (!res.ok) {
        throw new Error('Failed to import class');
      }

      Amplitude.track('Created Class', {
        'Number of Students': cleverClass.data.students.length,
        'Grade': cleverGradeToKodableGrade(cleverClass.data.grade),
        'Upload Source': 'Clever'
      });

      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'success' }));
    } catch (error) {
      console.error(`Error importing class ${cleverClassId}:`, error);
      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'error' }));
      throw error;
    }
  };

  const handleDeleteClass = async (cleverClassId: string): Promise<void> => {
    try {
      const res = await justFetch(endpoints.deleteCleverKlass(cleverClassId), 'DELETE');

      if (!res.ok) {
        throw new Error('Failed to delete class');
      }

      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'success' }));
    } catch (error) {
      console.error(`Error deleting class ${cleverClassId}:`, error);
      setSubmitStatus(prev => ({ ...prev, [cleverClassId]: 'error' }));
      throw error;
    }
  };

  const renderStatusIcon = (status: SubmitStatusType) => {
    switch (status) {
      case 'submitting':
        return <CircularProgress size={32} />;
      case 'success':
        return <FontAwesomeIcon size="2x" icon={faCheckCircle} color={'green'} />;
      case 'error':
        return <FontAwesomeIcon size="2x" icon={faExclamationCircle} color={'red'} />;
      default:
        return <></>;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <LinearProgress style={{ visibility: isSubmitting ? 'visible' : 'hidden' }} />
      {isValidating &&
        <DialogTitle>
          Loading Classes...
        </DialogTitle>
      }
      {!isValidating &&
        <DialogTitle>
          {!cleverClassesError? "Select classes to share with Kodable" : "Sign into Clever to Import Classes"}
        </DialogTitle>
      }
      <DialogContent>
        {isValidating && <Box display={'flex'} justifyContent={'center'}><CircularProgress color={'primary'} /></Box>}
        {!cleverClassesError && !isValidating &&
          <Box component={Paper} {...{ variant: 'outlined' }} maxHeight="50vh" overflow="scroll">
            <Table stickyHeader={true} size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>Class</TableCell>
                </TableRow>
              </TableHead>
              {cleverClasses && cleverClasses.length > 0 && <TableBody>
                {cleverClasses?.map(({ data: { id, name } }) => {
                  const selected = selectedClasses.has(id);
                  const status = submitStatus[id] || 'submitting';

                  return (
                    <TableRow key={id}>
                      <TableCell onClick={() => !isSubmitting && handleToggleClass(id, !selected)}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'left'} alignItems={'center'}>
                          {!isSubmitting ? (
                            <Checkbox
                              checked={selected}
                              onChange={() => handleToggleClass(id, !selected)}
                            />
                          ) : (
                            <Box mr={2} width={32} height={40} alignContent={'center'}>
                              {renderStatusIcon(status)}
                            </Box>
                          )}
                          {name}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {allKlasses?.filter(({ clever_id }) => clever_id && !cleverIds.includes(clever_id)).map(klass => {
                  const status = submitStatus[klass.clever_id || ''] || 'submitting';
                  return (
                    <TableRow key={klass.clever_id}>
                      <TableCell>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'left'} alignItems={'center'}>
                          <Tooltip title={'This class does not exist in Clever and will be removed.'} placement={'right'}>
                            <span>
                              {!isSubmitting ? (
                                <Checkbox
                                  checked={false}
                                  disabled={true}
                                />
                              ) : (
                                <Box mr={2} width={32} height={40} alignContent={'center'}>
                                  {renderStatusIcon(status)}
                                </Box>
                              )}
                            </span>
                          </Tooltip>
                          {klass.klass_name}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              }
            </Table>
          </Box>
        }
        {cleverClassesError && !isValidating &&
          <CleverLoginButton/>
        }
      </DialogContent>
      {!cleverClassesError && !isValidating &&
        <DialogActions>
          <Box display={'flex'} flexDirection={'row-reverse'} width={'100%'}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >Save</Button>
          </Box>
        </DialogActions>
      }
    </Dialog>
  )
}

export default ManageKlassesDialog