import {useOrganizationSelection} from "../../../context/OrganizationSelectionProvider";
import useCurrentUser from "../../../loaders/useCurrentUser";
import useSWR from "swr";
import {getPlanForRenewal, ISalesProfile} from "../../settings/teacher/ISalesProfile";
import endpoints from "../../../endpoints";
import React, {useLayoutEffect, useState} from "react";
import useSharedStyles from "../../../components/useSharedStyles";
import useDialogState from "../../../hooks/useDialogState";
import {useHistory, useParams} from "react-router-dom";
import * as Yup from "yup";
import {FormikProvider, useFormik} from "formik";
import {justFetch} from "../../../mutations/mutate";
import {routes} from "../../../routes";
import {Box, Checkbox, Divider, FormControlLabel, FormGroup, Link, MenuItem, Typography} from "@material-ui/core";
import PageHeader from "../../../components/ui/PageHeader";
import {SalesQuoteSameContactModal} from "../SalesQuoteSameContactModal";
import TextField from "../../../components/ui/TextField";
import Select from "../../../components/ui/Select";
import Button from "../../../components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Alert} from "@material-ui/lab";
import {QuickQuoteBlock} from "./CreateQuoteStart";
import PageContainer from "../../../components/ui/PageContainer";
import { IRouteParams } from "AppRoutes";
import { IQuote } from "../IQuote";


const CreateQuotePage: React.VFC = () => {
  const {
    organizationProfile,
    displayName,
    setDisplayName,
    siteType,
    setSiteType,
    isOutsideUS,
    setIsOutsideUS,
    schoolId,
    setSchoolId,
    districtId,
    setDistrictId,
    hasCurriculum
  } = useOrganizationSelection()
  const {currentUser} = useCurrentUser()
  const {data: salesProfile} = useSWR<ISalesProfile>((organizationProfile?.administrator_id || currentUser?.id) ? endpoints.salesProfile(organizationProfile?.administrator_id || currentUser?.id) : null);
  const planForRenewal = salesProfile && getPlanForRenewal(salesProfile);
  const [submitError, setSubmitError] = useState<false | string>(false);
  const sharedClasses = useSharedStyles();
  const [billingContactIsSubmitter, setBillingContactIsSubmitter] = useState<boolean>(false)
  const cannotEditDisplayName = (!!organizationProfile || (!!schoolId && schoolId > 0) || (!!districtId && districtId > 0))
  const areYouSureDialogState = useDialogState()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const history = useHistory()

  const { secret_code } = useParams<IRouteParams>();
  const { data: editQuote, error } = useSWR<IQuote>(endpoints.quote(secret_code));

  const getValidationSchema = () => {
    return Yup.object({
      display_name: Yup.string().required('Please enter an organization name'),
      billing_contact_name: Yup.string().required('Please enter a name'),
      billing_contact_email: Yup.string().required('Please enter a contact email').email('Please enter a valid contact email'),
      submitter_name: Yup.string().required('Please enter a valid name'),
      submitter_email: Yup.string().required('Please enter an email').email('Please enter a valid email'),
      num_sites: Yup.number().min(1, 'Enter a valid number').max(25, 'Enter a valid number').required('Enter the # of sites'),
      site_names: Yup.array()
        .when('num_sites', {
          is: (numSites) => numSites > 1,
          then: Yup.array()
            .required('Names of school sites are required')
            .min(Yup.ref('num_sites'), `You must enter a name for each site`)
            .of(Yup.string().required('School names cannot be blank')),
          otherwise: Yup.array().notRequired()
        }),
      has_curriculum: Yup.bool().required('Please select a plan type'),
    });
  }

  const getSiteNames = () : string[] => 
  {
    if (planForRenewal)
      return planForRenewal.licenses.map(license => license.site_name);

    if (displayName || organizationProfile?.display_name)
      return [displayName || organizationProfile?.display_name || '']

    return [];
  }

  const getInitialValues = ()=> {
    if (editQuote)
    {
      return {
        organization_profile_id: editQuote.organization_profile_id,
        num_sites: editQuote.num_sites,
        site_names: editQuote.site_names || [editQuote.organization_name],
        has_curriculum: editQuote.has_curriculum,
        display_name: editQuote.organization_name,
        submitter_name: currentUser?.name || '',
        submitter_email: currentUser?.username || '',
        billing_contact_name: editQuote.contact_name,
        billing_contact_email: editQuote.contact_email,
        teacher_id: editQuote.teacher_id,
        school_id: organizationProfile?.school_id || null, // organizationProfile is set in CreateQuoteFormWrapper. consider moving logic from CreateQuoteFormWrapper to here for editting existing quotes
        district_id: organizationProfile?.district_id || null,
        is_outside_us: editQuote.is_outside_us
      }
    }
    // else no quote
    return {
      organization_profile_id: organizationProfile?.id || null,
      num_sites: planForRenewal?.num_sites || 1,
      site_names: getSiteNames(),
      has_curriculum: hasCurriculum || false,
      display_name: displayName || organizationProfile?.display_name,
      submitter_name: currentUser?.name || '',
      submitter_email: currentUser?.username || '',
      billing_contact_name: organizationProfile?.billing_contact_name,
      billing_contact_email: organizationProfile?.billing_contact_email,
      teacher_id: currentUser?.id || null,
      school_id: organizationProfile?.school_id || schoolId || null,
      district_id: organizationProfile?.district_id || districtId || null,
      is_outside_us: isOutsideUS
    }
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: getValidationSchema(),
    onSubmit: async values => {
      setSubmitting(true)
      try {
        const res = editQuote? 
          await justFetch(endpoints.quote(secret_code), 'PUT', {
            ...values,
            organization_name: values.display_name,
            submitter_name: values?.submitter_name!.trim(),
            submitter_email: values?.submitter_email!.trim(),
            contact_email: values?.billing_contact_email!.trim(),
            contact_name: values?.billing_contact_name!.trim(),
          })        
          : await justFetch(endpoints.createQuote, 'POST', {
            ...values,
            submitter_name: values?.submitter_name!.trim(),
            submitter_email: values?.submitter_email!.trim(),
            billing_contact_email: values?.billing_contact_email!.trim(),
            billing_contact_name: values?.billing_contact_name!.trim(),
          });

        const body = await res.json()
        setSubmitting(false)
        if (res.ok) {
          history.push(routes.sales.quotes.view(body.secret_code));
          return
        }
        setSubmitError(body.message || body.error);
      } catch (error: any) {
        setSubmitting(false)
        setSubmitError(error.toString())
      }
    }
  })

  const handleBillingContactIsSubmitter = () => {
    if (!billingContactIsSubmitter) {
      areYouSureDialogState.handleOpen()
    }
    else {
      setBillingContactIsSubmitterDetails(false)
    }
  }

  const setBillingContactIsSubmitterDetails = async (submitterIsBillingContact: boolean) => {
    await form.setFieldValue('billing_contact_name', submitterIsBillingContact ? form.values.submitter_name : '');
    await form.setFieldValue('billing_contact_email', submitterIsBillingContact ? form.values.submitter_email : '');
    await form.validateForm()
    setBillingContactIsSubmitter(submitterIsBillingContact);
  }

  useLayoutEffect(() => {
    const validate = async () => {
      await form.validateForm();
    }
    validate()
  }, [form.values])

  return (
    <PageContainer variant="centered">
      <FormikProvider
        value={form}
      >
        <form onSubmit={form.handleSubmit} className={sharedClasses.vspacing2}>
          <PageHeader title={`Create a${planForRenewal ? " Renewal " : " "}Quote for your Organization`}/>
          <SalesQuoteSameContactModal dialogState={areYouSureDialogState}
                                      setBillingContactIsSubmitter={setBillingContactIsSubmitterDetails}/>
          <TextField
            id="display_name"
            name="display_name"
            label="School, District or Organization Name"
            value={form.values.display_name}
            onChange={(value) => {
              form.setFieldValue('display_name', value.target.value)
//              form.setFieldValue('site_names[0]', value.target.value)
            }}
            error={form.submitCount > 0 && !!form.errors.display_name}
            helperText={form.submitCount > 0 ? form.errors.display_name : ''}
            disabled={cannotEditDisplayName}
          />
          <Box className={sharedClasses?.vspacing2} display="flex" alignItems="flex-start" justifyContent="space-between"
               flexDirection={'column'}>
            <Box className={sharedClasses?.hspacing2} width="calc(100% - 8px)" display={'flex'} flexDirection={'row'}>
              <TextField
                id="submitter_name"
                name="submitter_name"
                label="Your Name"
                fullWidth
                onBlur={form.handleBlur}
                value={form.values.submitter_name}
                onChange={e => form.setFieldValue('submitter_name', e.target.value)}
                error={form.touched.submitter_name && Boolean(form.errors.submitter_name)}
                helperText={form.touched.submitter_name && form.errors.submitter_name}
              />
              <TextField
                id="submitter_email"
                name="submitter_email"
                label="Your Email"
                fullWidth
                onBlur={form.handleBlur}
                value={form.values.submitter_email}
                onChange={e => form.setFieldValue('submitter_email', e.target.value.trim())}
                error={form.touched.submitter_email && Boolean(form.errors.submitter_email)}
                helperText={form.touched.submitter_email && form.errors.submitter_email}
              />
            </Box>
          </Box>
          <Divider/>
          {(form.values.submitter_email && !Boolean(form.errors.submitter_email)) &&
            (form.values.submitter_name && !Boolean(form.errors.submitter_name)) &&
            (
              <>
                <Box className={sharedClasses?.vspacing2} display="flex" alignItems="flex-start"
                     justifyContent="space-between" flexDirection={'column'}>
                  <Box display="flex" justifyContent="flex-start">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={billingContactIsSubmitter}/>}
                        label="Same as my contact info"
                        onChange={handleBillingContactIsSubmitter}
                      />
                    </FormGroup>
                  </Box>
                  <Box className={sharedClasses?.hspacing2} width="calc(100% - 8px)" display={'flex'}
                       flexDirection={'row'}>
                    <TextField
                      id="billing_contact_name"
                      name="billing_contact_name"
                      label="Billing Contact Name"
                      fullWidth
                      onBlur={form.handleBlur}
                      value={form.values.billing_contact_name}
                      onChange={e => form.setFieldValue('billing_contact_name', e.target.value)}
                      error={form.touched.billing_contact_name && Boolean(form.errors.billing_contact_name)}
                      helperText={form.touched.billing_contact_name && form.errors.billing_contact_name}
                      disabled={billingContactIsSubmitter}
                    />
                    <TextField
                      id="billing_contact_email"
                      name="billing_contact_email"
                      label="Billing Contact Email"
                      fullWidth
                      onBlur={form.handleBlur}
                      value={form.values.billing_contact_email}
                      onChange={e => form.setFieldValue('billing_contact_email', e.target.value.trim())}
                      error={form.touched.billing_contact_email && Boolean(form.errors.billing_contact_email)}
                      helperText={form.touched.billing_contact_email && form.errors.billing_contact_email}
                      disabled={billingContactIsSubmitter}
                    />
                  </Box>
                </Box>
                <Divider/>
              </>
            )
          }
          <Box className={sharedClasses?.vspacing2} display="flex" justifyContent="space-between" alignItems="flex-end"
               flexWrap="wrap">
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="flex-end">
              <Box width="calc(50% - 8px)">
                <TextField
                  fullWidth
                  id="num_sites"
                  name="num_sites"
                  label="How many separate physical locations will be using Kodable?"
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 25,
                    step: 1
                  }}
                  value={form.values.num_sites}
                  onChange={async (e) => {
                    let numSites = parseInt(e.target.value)
                    await form.setFieldValue('num_sites', numSites)

                    let newSiteNames = form.values.site_names.slice(0, numSites);
                    if (newSiteNames.length < numSites) {
                      newSiteNames = newSiteNames.concat(Array(numSites - newSiteNames.length).fill(''));
                    }
                    await form.setFieldValue('site_names', newSiteNames);
                    form.validateForm();                    
                  }}
                />
              </Box>
              <Box width="calc(50% - 8px)" display="flex" justifyContent="space-between" alignItems="flex-start"
                   className={sharedClasses.vspacing2}>
                <Box width="100%">
                  <Select
                    id="has_curriculum"
                    name="has_curriculum"
                    label={<Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1">Plan</Typography>
                      &nbsp;
                      <Typography variant="body2">
                        <Link href={`${process.env.REACT_APP_KODABLE_WEBSITE}/plans`} target="_blank">view our plan
                          options</Link>
                      </Typography>
                    </Box>}
                    value={form.values.has_curriculum}
                    onChange={form.handleChange}
                    helperText={form.submitCount > 0 ? form.errors.has_curriculum : ''}
                    fullWidth
                  >
                    <MenuItem value={false as any} selected={!form.values.has_curriculum}>Premium</MenuItem>
                    <MenuItem value={true as any} selected={form.values.has_curriculum}>Premium+</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" className={sharedClasses.hspacing2}>
            {Array.from({length: form?.values?.num_sites as number}, (_, index) => {
              if (form?.values?.num_sites && form?.values?.num_sites < 2) {
                return null
              }
              // Otherwise, render the site_names field for each index after 0
              return (
                <Box width="calc(50% - 16px)" marginBottom="16px" key={index}>
                  <TextField
                    fullWidth
                    id={`site_names[${index}]`}
                    name={`site_names[${index}]`}
                    label={`School Name ${index + 1}`}
                    type="text"
                    value={form?.values?.site_names[index] || ''}
                    onChange={(e) => {
                      form?.setFieldValue(`site_names[${index}]`, e.target.value);
                    }}
                    error={form.submitCount > 0 && !!form?.errors?.site_names?.[index]}
                    helperText={!!form?.values?.site_names?.[index] ? '' : 'School Name cannot be empty'}
                  />
                </Box>
              );
            })}
          </Box>
          <Box width="calc(50% - 8px)">
            <QuickQuoteBlock/>
          </Box>
          <Divider/>
          <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <Button
              onClick={() => {
                if (siteType === 'other') {
                  setIsOutsideUS(null)
                  setSiteType(null)
                  return
                }
                if (siteType === 'school') {
                  setSchoolId(null)
                }
                if (siteType === 'district') {
                  setDistrictId(null)
                }
                setDisplayName('')
                history.goBack()
              }}
              variant="outlined"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
            >
              {editQuote? "Cancel" : "Back"}
            </Button>
            <Button
              variant="contained"
              color="blue"
              endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
              type="submit"
              disabled={form.isSubmitting || submitting || !form.isValid}
            >Save and View Quote</Button>
          </Box>

          {submitError && <Alert severity="error">{submitError}</Alert>}
        </form>
      </FormikProvider>
    </PageContainer>
  )
}

export default CreateQuotePage