import React, {useLayoutEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import {justFetch} from "mutations/mutate";
import {Box, CircularProgress, FormControlLabel, makeStyles, Paper, Switch, Typography, useTheme} from "@material-ui/core";
import {DataGrid, GridCellParams, GridColDef, GridRowData, GridValueFormatterParams} from "@material-ui/data-grid";
import {accountTrackedDate} from "pages/godmode/utils/time";
import {formatCurrency} from "pages/godmode/utils/numbers";
import TextField from "components/ui/TextField";
import Button from "components/ui/buttons/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import endpoints from "endpoints";
import {Link, } from "react-router-dom";
import {routes} from "routes";
import { IOrganizationProfile } from 'types/IOrganizationProfile';
import MultiSelector from "../../../../components/ui/MultiSelector";
import {filter} from "lodash";

export type OrganizationProfilePayload = {
    organization_profiles: IOrganizationProfile[],
    current_page: number,
    page_count: number,
    total_records: number
}

export type PaginationData = {
    currentPage: number,
    pageCount: number,
    totalRecords: number
}


const columns: GridColDef[] = [
    { field: 'display_name', headerName: 'Org Name', width: 250 },
    { field: 'contract_amount', headerName: 'Amount', width: 100, type: 'currency' },
    { field: 'administrator_name', headerName: 'Admin Name', width: 200 },
    { field: 'administrator_username', headerName: 'Admin Email', width: 200 },
    { field: 'current_subscription_start_date', headerName: 'Current Sub Start Date', width: 150, type: 'date' },
    { field: 'current_subscription_end_date', headerName: 'Current Sub End Date', width: 150, type: 'date' },
    { field: 'status', headerName: 'Status', width: 120, type: 'account_status' },
]


const useStyles = makeStyles((theme) => ({
    root: {
        '& .paid, & .expired, & .upcoming, & .delinquent': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize'
        },
        '& .delinquent': {
            backgroundColor: theme.palette.red.main
        },
        '& .paid': {
            backgroundColor: theme.palette.green.main,
        },
        '& .expired': {
            backgroundColor: theme.palette.warning.main,
        },
        '& .upcoming': {
            backgroundColor: theme.palette.blue.main,
        }
    },
}));


const OrganizationProfilesTable: React.FC = () => {
    const [pageSize, setPageSize] = useState(50);
    const [organizationProfiles, setOrganizationProfiles] = useState<IOrganizationProfile[]>([]);
    const [paginationData, setPaginationData] = useState<PaginationData>();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const rowStyles = useStyles();
    const theme = useTheme()
  
    const statusOptions = ['paid', 'delinquent', 'expired', 'upcoming']

    const getPage = async (page: number) => {
        setLoading(true)
        const data = await justFetch(endpoints.godmode.organizationProfile.all(
                page,
                pageSize,
                'created_at',
                'DESC',
                searchQuery,
                ['administrator', 'current_subscription', 'subscriptions', 'purchase_process'],
                false
            ),
            'GET'
        )
        const payload: OrganizationProfilePayload = await data.json();
        setOrganizationProfiles(payload.organization_profiles.map((organization_profile: IOrganizationProfile) => {
            const today = new Date()
            const sortedSubs = organization_profile?.subscriptions?.sort((a, b) => {
                return new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
            })

            if (!sortedSubs || sortedSubs.length < 1) {
                return {
                    ...organization_profile,
                    administrator_name: organization_profile?.administrator?.name,
                    administrator_username: organization_profile?.administrator?.username,
                    status: 'expired'
                }
            }
            
            const currentSub = sortedSubs?.find((sub) => sub?.id === organization_profile?.current_subscription?.id)
            const mostRelevantSubscription = currentSub ? currentSub : sortedSubs[0]

            const startDate = new Date(mostRelevantSubscription?.start_date)
            const endDate = new Date(mostRelevantSubscription?.end_date)

            const getOrganizationProfileStatus = (): string => {
                if (mostRelevantSubscription?.purchase_process?.state === 'delinquent') {
                    return 'delinquent'
                }
                if (endDate < today) {
                    return 'expired'
                }
                if (startDate > today) {
                    return 'upcoming'
                }
                return 'paid'
            }
            
            return {
                ...organization_profile,
                contract_amount: mostRelevantSubscription?.contract_amount,
                administrator_name: organization_profile?.administrator?.name,
                administrator_username: organization_profile?.administrator?.username,
                current_subscription_start_date: mostRelevantSubscription?.start_date,
                current_subscription_end_date: mostRelevantSubscription?.end_date,
                status: getOrganizationProfileStatus()
            }
        }))
        setPaginationData({
            currentPage: payload.current_page,
            pageCount: payload.page_count,
            totalRecords: payload.total_records
        });
        setLoading(false);
    }
    
    const filteredProfiles = useMemo(() => {
        if (!organizationProfiles) {
            return []
        }
        const filteredData = organizationProfiles as GridRowData[]
        if (selectedStatus.length === 0) {
            return filteredData;
        }
        return filteredData.filter((data) => selectedStatus.includes(data.status ?? ""))
    }, [organizationProfiles, selectedStatus]);

    const addColumnFormatters = (columns: GridColDef[]) => {
        return columns.map((column) => {
            if (column.type && column.type === 'date') {
                column.valueFormatter = (params: GridValueFormatterParams) => {
                    if (!params.value) {
                        return 'N/A';
                    }
                    return accountTrackedDate(params.value as string);
                }
            }
            if (column.type && column.type === 'currency') {
                column.valueFormatter = (params: GridValueFormatterParams) => {
                    if (!params.value) {
                        return 'N/A'
                    }
                    return formatCurrency(Number(params.value as number));
                }
            }
            if (column.field === 'display_name') {
                column.renderCell = (params: GridValueFormatterParams) => {
                    return (
                        <Link to={routes.godmode.organizationProfiles.view(params.row.id)}>{params.value}</Link>
                    )
                }
            }
            if (column.field === 'administrator_name' || column.field === 'administrator_username') {
                column.renderCell = (params: GridValueFormatterParams) => {
                    return (
                        <Link
                            style={{color: theme.palette.blue.main}}
                            to={routes.godmode.user.index(params.row.administrator_id)}
                        >
                            {params.value}
                        </Link>
                    )
                }
            }
            if (column.type && column.type === 'account_status') {
                column.cellClassName = (params: GridCellParams) => {
                    return clsx({
                        expired: params.value === 'expired',
                        paid: params.value === 'paid',
                        upcoming: params.value === 'upcoming',
                        delinquent: params.value === 'delinquent'
                    })
                }
            }
            return column
        })
    }

    useLayoutEffect(() => {
        getPage(1);
    }, [selectedStatus])

    return (
        <div style={{width: '100%' }}>
            <Box display="flex" alignItems="center" mb={1} mt={1}>
                <Box flexGrow={1}>
                    <TextField
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                getPage(1)
                                ev.preventDefault();
                            }
                        }}
                        name="query"
                        id="query"
                        placeholder={"Search"}
                        fullWidth
                    />
                </Box>
                <Box ml={1}>
                    <Button
                        color="green"
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={faSearch} />}
                        size="large"
                        type="submit"
                        onClick={() => getPage(1)}
                    >
                        Search
                    </Button>
                </Box>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                    marginBottom: '1rem'

                }}
            >
              <MultiSelector title={"Status"} options={statusOptions} currentSelection={selectedStatus} setSelection={setSelectedStatus} />
            </Box>
            {loading &&
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2rem'
                    }}
                >
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Paper>
                    <div className={rowStyles.root}>
                        <DataGrid
                            rows={filteredProfiles}
                            columns={addColumnFormatters(columns)}
                            pageSize={pageSize}
                            autoHeight
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            page={paginationData?.currentPage}
                            onPageSizeChange={(newPageSize) => {
                                setPageSize(newPageSize);
                            }}
                            paginationMode="server"
                            onPageChange={(newPage) => {
                                if (newPage === 0) {
                                    return;
                                }
                                if (paginationData?.currentPage && newPage < paginationData?.currentPage) {
                                    getPage(newPage - 1)
                                }
                                getPage(newPage+1)
                            }}
                            pagination
                            rowCount={paginationData?.totalRecords}
                        />
                    </div>
                </Paper>
            }
        </div>
    )
}

export default OrganizationProfilesTable;