import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@material-ui/core"
import Button from "components/ui/buttons/Button"
import TextField from "components/ui/TextField"
import useSharedStyles from "components/useSharedStyles"
import { useAlert } from "context/AlertProvider"
import endpoints from "endpoints"
import { FormikProvider, useFormik } from "formik"
import useDialogState from "hooks/useDialogState"
import { justFetch } from "mutations/mutate"
import { formatCurrency } from "pages/godmode/utils/numbers"
import React, { useEffect, useState } from "react"
import useSWR from "swr"
import { ISchoolSubscriptionPricing, ISubscription } from "types/ISubscription"
import SearchSchoolDialog from "../../Orders/components/SearchSchoolDialog"
import { SchoolInfoType } from "../../Orders/components/SiteToSchoolBox"

const OrganizationPlanUpgradeModal: React.FC<{subscription: ISubscription | undefined}> = ({subscription}) => {
    const maxNumSites = 25;
    const dialogState = useDialogState()
    const { data: subscriptionPricing } = useSWR<ISchoolSubscriptionPricing>(endpoints.godmode.subscriptions.schoolPricing)
    const [isPaid, setIsPaid] = useState<boolean>(false)
    const [upgradeOperation, setUpgradeOperation] = useState<'premium_plus' | 'add_site'>('premium_plus')
    const sharedClasses = useSharedStyles()
    const alert = useAlert()
    const theme = useTheme();
    const searchSchoolDialogState = useDialogState();
    const [searchSchoolSiteIndex, setSearchSchoolSiteIndex] = useState<number>(0);

    const form = useFormik({
        initialValues: {
            custom_discount: 0,
            upgrade_add_licenses_count: subscription?.license_count || 1,
            site_ids: [0],
            site_names: ['']
        },
        onSubmit: async (values) => {
            if (getInvoiceTotal() < 0) {
                alert.error('Discount cannot exceed the total invoice amount')
                return
            }
            const res = upgradeOperation == 'premium_plus'? 
                await justFetch(endpoints.godmode.subscriptions.upgradeToPremiumPlus(subscription?.id), 'POST', {
                    upgrade_premium_plus: true,
                    custom_discount: values.custom_discount,
                    prorated_credit: getProratedCredit()
                }): 
                await justFetch(endpoints.godmode.subscriptions.upgradeAddLicenses(subscription?.id), 'POST', {
                    ...values,
                    prorated_credit: getProratedCredit(),
            });

            if (res.ok) {
                dialogState.onClose()
                alert.success('Subscription upgraded successfully')
                return
            }

            const error = await res.json()

            alert.error(error?.message || 'An error occurred while upgrading the subscription')
        }
    })

    const monthsRemaining = ( endDate: string | undefined) =>
    {
        if (!endDate)
            return 0;

        // calculate months from current month to end month of subscription inclusive
        const today = new Date();
        const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const end = new Date(endDate);
        const monthsBetween = (end.getFullYear() - currentMonthStart.getFullYear()) * 12 + (end.getMonth() - currentMonthStart.getMonth());
        return Math.max(0, monthsBetween);
    }

    const getContractDuration = () =>
    {
        return Number(subscription?.contract_duration) || 12;
    }

    const getNewContractPrice = () =>
    {
        if (!subscriptionPricing)
            return 0;
        if (upgradeOperation == 'premium_plus')
            return (subscriptionPricing?.premium_plus || 0) * getContractDuration() / 12;
        const pricePerLicense = subscription?.has_curriculum? subscriptionPricing.premium_plus : subscriptionPricing.premium;
        return pricePerLicense * (form.values.upgrade_add_licenses_count + (subscription?.license_count || 0)) * getContractDuration() / 12;
    }

    const getProratedCredit = (): number => {
        if (!subscription)
            return 0
        const monthsConsumed = getContractDuration() - monthsRemaining(subscription?.end_date);
        return (getNewContractPrice() - subscription.contract_amount) * (monthsConsumed/getContractDuration());
    }

    const getInvoiceTotal = () : number => {
        if (!subscription || !subscriptionPricing) {
            return 0
        }
        let invoiceTotal = getNewContractPrice() - getProratedCredit() - form.values.custom_discount;
        if (isPaid && subscription?.contract_amount) {
            invoiceTotal -= subscription?.contract_amount;
        }
        return Math.max(invoiceTotal, 0);
    }

    useEffect(() => {
        if (!subscription) {
            return
        }
        if (subscription?.purchase_process?.state === 'paid') {
            setIsPaid(true)
        }
    }, [subscription])

    if (!subscriptionPricing) {
        return null
    }
    
  const updateSchoolInfo = (schoolInfo: SchoolInfoType, nonTraditionalSchoolName?: string) => {
    const siteNames = [...form.values.site_names];
    siteNames[searchSchoolSiteIndex] = schoolInfo?.id ? schoolInfo.school_name : (nonTraditionalSchoolName || "School not set");
    form.setFieldValue('site_names', siteNames);

    const siteIds = [...form.values.site_ids];
    siteIds[searchSchoolSiteIndex] = schoolInfo?.id ? schoolInfo.id : 0;
    form.setFieldValue('site_ids', siteIds);

  }    
    return (
        <>
        <SearchSchoolDialog
            open={searchSchoolDialogState.open}
            onClose={searchSchoolDialogState.onClose}
            searchOptions={{ nonTraditionalSchoolName: form?.values.site_names[searchSchoolSiteIndex] || ''}}
            onSuccess={updateSchoolInfo}
            enableSetName={true}
        />
        <Box>
            <Dialog open={dialogState.open} fullWidth={true} maxWidth="md" >
                <DialogTitle>{upgradeOperation == 'premium_plus'? "Upgrade to Premium+" : "Add License(s)"}</DialogTitle>
                <DialogContent>
                    <Box className={sharedClasses?.vspacing2}>
                        <Typography variant="h6" align="center">
                            {upgradeOperation == 'premium_plus'? 
                                "Are you sure you would like to upgrade this Organization to Premium+?" : 
                                "Organization has " + subscription?.license_count + " license(s). How many new licenses do you want to add?"}
                        </Typography>
                        {upgradeOperation == 'add_site' && <>

                                <TextField
                                    id="upgrade_add_licenses_count"
                                    name="upgrade_add_licenses_count"
                                    label="Number of new Licenses: "
                                    type="number"
                                    inputProps={{
                                        min: 1,
                                        max: Math.max(25 - (subscription?.license_count || 0), 1),
                                        step: 1
                                    }}
                                    value={form.values.upgrade_add_licenses_count}
                                    onChange={(e) => {
                                        const value = e.target.value === '' ? '' : Math.max(1, Math.min(Number(e.target.value), maxNumSites));
                                        form.setFieldValue('upgrade_add_licenses_count', value);

                                        const numSites = parseInt(value as string, 10) || 1;
                                        if (numSites)
                                        {
                                            const siteNames = (form?.values?.site_names || []).slice(0, numSites).concat(Array(Math.max(numSites - (form?.values?.site_names?.length || 0), 0)).fill(''));;
                                            form.setFieldValue('site_names', siteNames);

                                            const siteIds = (form?.values?.site_ids || []).slice(0, numSites).concat(Array(Math.max(numSites - (form?.values?.site_ids?.length || 0), 0)).fill(''));
                                            form.setFieldValue('site_ids', siteIds);
                                        }
                                    }}
                                />


                            <Box >
    
                                {Array.from({length: form?.values?.upgrade_add_licenses_count as number}, (_, index) => {
                                return (
                                <Box 
                                    display="flex"
                                    flexWrap="wrap"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={sharedClasses.hspacing2}
                                        style={{
                                        minWidth: '100%',
                                        padding: 15,
                                        borderWidth: 3,
                                        borderColor: form?.values?.site_names[index] !== '' ? theme.palette.green.main : theme.palette.red.main,
                                        borderRadius: 8,
                                        marginBottom: 16,
                                        marginTop: 0
                                    }}>
                                        <TextField
                                            id={`site_names[${index}]`}
                                            name={`site_names[${index}]`}
                                            label={`School ${index + 1}`}
                                            type="text"
                                            value={ (form?.values?.site_names?.[index])?
                                                    (form?.values?.site_names?.[index] + (form?.values?.site_ids?.[index]? '' : ' [Non traditional]')):
                                                    'Select school..'
                                            }
                                            InputProps={{
                                                readOnly: true,  // Makes the field read-only, so it can't be edited but looks normal
                                            }}                                            
                                            style={{ flexGrow: 1, marginRight: '16px', width: '675px' }}
                                        />
                                        <Button
                                            variant="outlined"
                                            color="primary"                 
                                             style={{ minWidth: '150px', marginTop: '25px' }}
                                            onClick={() => {
                                                setSearchSchoolSiteIndex(index);
                                                searchSchoolDialogState.handleOpen();
                                            }}
                                            >
                                            Set School
                                        </Button>
                                    </Box>
                                );
                                })}
                        
                            </Box>                        
                        </>    
                        }

                        <FormikProvider value={form}>
                            <TextField
                                label="Custom Discount"
                                name="custom_discount"
                                type="number"
                                helperText="Custom Discount will only be applied to the current term. Subsequent renewals will be processed at full price."
                                value={form.values.custom_discount}
                                onChange={form.handleChange}
                            />
                        </FormikProvider>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Months Remaining</TableCell>
                                        <TableCell>{monthsRemaining(subscription?.end_date)} Months</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Old Price</TableCell>
                                        <TableCell>{formatCurrency(subscription?.contract_amount)} {`(${isPaid ? 'Paid' : 'Unpaid'})`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>New Subscription Base Price</TableCell>
                                        <TableCell>{formatCurrency(getNewContractPrice())}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Prorated Credit</TableCell>
                                        <TableCell>{formatCurrency(-getProratedCredit())}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><strong>Invoice Net Price</strong></TableCell>
                                        <TableCell>
                                            <strong>{formatCurrency(getInvoiceTotal())} </strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            {isPaid &&
                                <Typography align="center">A new invoice will be sent for the difference in price between the paid invoice and the new plan</Typography>
                            }
                            {!isPaid &&
                                <Typography align="center">The existing invoice will be updated to reflect the new price</Typography>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            variant="contained"
                            color="red"
                            onClick={() => dialogState.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="blue"
                            onClick={() => form.handleSubmit()}
                            disabled={upgradeOperation == 'add_site' && form?.values?.site_names.some(item => item == '')}
                        >
                            Confirm Upgrade
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {!subscription?.has_curriculum &&
                <Button
                    variant="outlined"
                    style={{ marginRight: '10px' }}
                    color="blue"
                    onClick={() => {
                        setUpgradeOperation('premium_plus')
                        dialogState.handleOpen()
                    }}
                >
                    Upgrade to Premium+
                </Button>
            }
            <Button
                variant="outlined"
                color="blue"
                onClick={() => {
                    setUpgradeOperation('add_site')
                    dialogState.handleOpen()
                }}
            >
                Add License to Subscription
            </Button>
        </Box>
        </>
    )
}

export default OrganizationPlanUpgradeModal